import React from 'react'
import { About } from '../Components/About/About'
import { Footer } from '../Components/Footer/Footer'
import { Hero } from '../Components/Hero/Hero'
import { Tools } from '../Components/Tools/Tools'

export const Home = () => {
  return (
    <div>
        <Hero />
        <Tools />
        <About />
        <Footer />
    </div>
  )
}