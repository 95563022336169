import React from 'react'
import './../Components/Chatbot.css'

export const Chatbot = () => {
  return (
    <div>
      <div className="container d-flex align-items-center flex-column  idontknow">
        <p className="h1">CEASER</p>
        <div className="chat-container"></div>
        <div className="input-group mb-0">
          <div className="input-group-text"><i className="fa-solid fa-magnifying-glass"></i></div>
          <input type="text" id="user-message" className="form-control"/>
            <button id="send-button" className="btn btn-primary">Send</button>
        </div>
      </div>
    </div>
  )
}