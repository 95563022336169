import './../node_modules/bootstrap/dist/css/bootstrap.css'
import './../node_modules/bootstrap/dist/js/bootstrap.bundle.js'

import { Navbar } from './Components/Navbar/Navbar';
import { BrowserRouter,Routes,Route } from 'react-router-dom'
import { Home } from './Pages/Home';
import { Tools } from './Pages/Tools';
import { Chatbot } from './Pages/Chatbot';
import { Music } from './Pages/Music';
import { LoginSinup } from './Pages/LoginSinup';


function App() {
  return (
    <div>
      <BrowserRouter>
        <Navbar />
        <Routes>
          <Route path='/' element={<Home/>}/>
          <Route path='/tools' element={<Tools/>}>
            <Route path=':toolsId' element={<Tools/>}/>
          </Route>
          <Route path='/chatbot' element={<Chatbot/>}/>
          <Route path='/music' element={<Music/>}/>
          <Route path='/login' element={<LoginSinup/>}/>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
