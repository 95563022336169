import React from 'react'
import { Card } from '../Components/Card/Card'

export const Tools = () => {
  return (
    <div>
      <div className="container hifun2 container-fluid">
        <p className="h1 text-capitalize">Tools</p>
        <div className="row gy-3 my-3">
          <div className="col-lg-4 col-md-5 col-sm-6 column-gap-md-4 d-flex">
            <Card name="To-Do List" disp="Manage your tasks efficiently with our To-Do List tool. You can add, edit, and delete tasks." />
          </div>
          <div className="col-lg-4 col-md-5 col-sm-6 column-gap-md-4 d-flex">
            <Card name="Calculator" disp="Perform basic arithmetic operations with this simple calculator." />
          </div>
          <div className="col-lg-4 col-md-5 col-sm-6 column-gap-md-4 d-flex">
            <Card name="Chatbot" disp="Interact with our chatbot for automated responses and assistance." />
          </div>
          <div className="col-lg-4 col-md-5 col-sm-6 column-gap-md-4 d-flex">
            <Card name="Music" disp="Explore and listen to music with this tool. Access various music tracks and playlists." />
          </div>
          <div className="col-lg-4 col-md-5 col-sm-6 column-gap-md-4 d-flex">
            <Card name="Pomodoro Timer" disp="Stay productive with our Pomodoro Timer. Work for 25 minutes, then take a 5-minute break." />
          </div>
          <div className="col-lg-4 col-md-5 col-sm-6 column-gap-md-4 d-flex">
            <Card name="Code Editor" disp="A simple code editor with syntax highlighting." />
          </div>
          <div className="col-lg-4 col-md-5 col-sm-6 column-gap-md-4 d-flex">
            <Card name="Dictionary" disp="Look up the meaning of any word." />
          </div>
          <div className="col-lg-4 col-md-5 col-sm-6 column-gap-md-4 d-flex">
            <Card name="Tic Tac Toe" disp="Play a game of Tic Tac Toe against yourself." />
          </div>
        </div>
      </div>
    </div>
  )
}