import React, { useState } from 'react'
import { Link } from 'react-router-dom';
import './Navbar.css'

export const Navbar = () => {
  
    const [menu, setMenu] = useState("home");

    return (
    <header>
        <nav className="navbar navbar-expand-lg">
            <div className="container-fluid">
                <Link to='/' className="navbar-brand me-auto">AUN</Link>
                <div className="offcanvas offcanvas-end" tabindex="-1" id="offcanvasNavbar" aria-labelledby="offcanvasNavbarLabel">
                    <div className="offcanvas-header">
                        <h5 className="offcanvas-title" id="offcanvasNavbarLabel">Welcome</h5>
                        <button className="btn-close" type="button" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                    </div>
                    <div className="offcanvas-body">
                        <ul className="navbar-nav justify-content-end flex-grow-1 pe-3">
                            <li onClick={()=>{setMenu("home")}} className="nav-item">
                                <Link to='/' className="nav-link mx-lg-2 home" aria-current="page">Home
                                {menu==="home"? <hr/>:<></>}
                                </Link>
                                
                            </li>
                            <li onClick={()=>{setMenu("tools")}} className="nav-item">
                                <Link to='/tools' className="nav-link mx-lg-2 tools">Tools
                                {menu==="tools"? <hr/>:<></>}
                                </Link>
                            </li>
                            <li onClick={()=>{setMenu("chatbot")}} className="nav-item">
                                <Link to='/chatbot' className="nav-link mx-lg-2 chatbot">Chatbot
                                {menu==="chatbot"? <hr/>:<></>}
                                </Link>
                            </li>
                            <li onClick={()=>{setMenu("music")}} className="nav-item">
                                <Link to='/music' className="nav-link mx-lg-2 music">Music
                                {menu==="music"? <hr/>:<></>}
                                </Link>
                            </li>
                        </ul>
                    </div>
                </div>
                <Link to='/login' class="login-button">Login</Link>
                <button class="navbar-toggler" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasNavbar" aria-controls="offcanvasNavbar" aria-label="Toggle navigation">
                    <span class="navbar-toggler-icon"></span>
                </button>
            </div>
        </nav>
    </header>
    )
}