import React from 'react'
import './Hero.css'

export const Hero = () => {
  return (
    <div>
        <div className="container hero-section d-flex align-items-center justify-content-center flex-column" id="home">
            <h2 className="hero_aun">AUN</h2>
            <h1 className="text-center hifun mt-3 mb-4">
                <span>A</span>ny Thing<br/> Yo<span>u</span> <span>N</span>eed
            </h1>
            <p className="text-md-center mb-5 w-50">
                Explore a suite of innovative tools and resources designed to empower you. Discover new ways to learn, create, and engage.
            </p>
            <a className="btn btn-primary" href="#">Create Account</a>
            <div className="h1 mt-5">
                <i className="fa-solid fa-computer-mouse"></i>
            </div>
        </div>
    </div>
  )
}