import React from 'react'
import './About.css'
import about1 from './../Assets/img/about1.JPG'
import about2 from './../Assets/img/about2.JPG'
import about3 from './../Assets/img/about3.JPG'

export const About = () => {
    return (
        <div>
            <div className="container about-section" id="about">
                <h2 className="section-title">About Us</h2>
                <div className="row hifun-stickey">
                    <div className="col-md-6 about-text">
                        <p>
                            Our platform is designed to provide you with the tools you need to succeed. Whether you're looking to manage your projects, improve your skills, or simply enjoy some music, we've got you covered.
                        </p>
                    </div>
                    <div className="col-md-6 about-images">
                        <div className="image-wrapper" data-aos="fade-up" data-aos-duration="1000">
                            <img src={about1} alt="About AUN" className="img-fluid rounded" />
                        </div>
                        <div className="image-wrapper" data-aos="fade-up" data-aos-duration="1000" data-aos-delay="200">
                            <img src={about2} alt="About AUN" className="img-fluid rounded" />
                        </div>
                        <div className="image-wrapper" data-aos="fade-up" data-aos-duration="1000" data-aos-delay="400">
                            <img src={about3} alt="About AUN" className="img-fluid rounded" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}