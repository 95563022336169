import React from 'react'
import './Card.css'

export const Card = (props) => {
    return (
        <div>
            <div className="card tool-card card-equal-height" >
                <div className="card-body">
                    <div className="card-title fw-bold">{props.name}</div>
                    <div className="card-text">{props.disp}</div>
                </div>
                <div className="pb-3 ps-3">
                    <a href="#" className="btn btn-primary">Use</a>
                </div>
            </div>
        </div>
    )
}




