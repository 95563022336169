import React from 'react'
import { Link } from 'react-router-dom'
import { CardTools } from '../CardTools/CardTools'
import './Tools.css'

export const Tools = () => {
    return (
        <div>
            <div className="container tools-section d-flex align-items-center justify-content-center flex-column" id="tools">
                <h2 className="section-title">Our Tools</h2>
                <p className="section-description mb-5 w-50 text-center">
                    Enhance your productivity with our cutting-edge tools designed to simplify your workflow and maximize efficiency.
                </p>
                <div className="row gy-4 my-4">
                    <CardTools title='Project Manager' dis='Stay on top of your tasks and manage projects with ease using our intuitive project management tool.' />
                    <CardTools title='Code Editor' dis='Write, edit, and manage code efficiently with our powerful and customizable code editor.' />
                    <CardTools title='Chatbot' dis='Interact with our AI-powered chatbot for instant assistance and support around the clock.' />
                    <CardTools title='Music Player' dis='Enjoy a seamless music experience with our built-in music player, offering a variety of tracks and playlists.' />
                </div>
                <Link className='btn btn-primary' to='/tools'>Tools</Link>
            </div>
        </div>
    )
}
