import React from 'react'

export const Footer = () => {
    return (
        <div>
            <footer className="container-fluid bg-dark">
                <div className="container footer-content">
                    <div className="row mt-5 pt-4 pb-4 footer-info">
                        <div className="col-lg-3 col-md-4">
                            <h5 className="text-light">Address</h5>
                            <p className="text-light">1234 Street Name</p>
                            <p className="text-light">City, State, 56789</p>
                        </div>
                        <div className="col-lg-3 col-md-4">
                            <h5 className="text-light">Contact</h5>
                            <p className="text-light">Phone: (123) 456-7890</p>
                            <p className="text-light">Email: revanthm180@gmail.com</p>
                        </div>
                        <div className="col-lg-3 col-md-4">
                            <h5 className="text-light">Follow Us</h5>
                            <p className="text-light">Stay connected through our social media channels for the latest updates.</p>
                        </div>
                        <div className="col-lg-3 col-md-4">
                            <h5 className="text-light">Feedback</h5>
                            <input type="email" className="input-group mb-3" placeholder="Enter your Email"/>
                                <textarea name="feedback" className="form-control" placeholder="Your feedback"></textarea>
                                <button type="button" className="btn btn-primary mt-2">Submit</button>
                        </div>
                    </div>
                </div>
            </footer>
        </div>
    )
}